import React from 'react';
import '../styles/Contact.css';
const Contact = () => {
  return (
    <div className="contact-container container">
      <div className="row">
        <div className="col-12">
          <div className="contact-title">Contact</div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <div className="contact-body">
            <div>
              I am not currently interested in new development opportunities.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
